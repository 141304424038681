import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { StoreNames } from '../../../shared/store-names';
import type { AvoidReplenishmentStatus } from '../interfaces';
import { useInventoryApi } from '../services/api';

export const useInventoryAvoidReplenishmentStatusStore = defineStore(
  StoreNames.InventoryAvoidReplenishmentStatus,
  () => {
    const status = ref<AvoidReplenishmentStatus>({
      skus: {
        active: 0,
        avoid: 0,
      },
      locations: {
        active: 0,
        avoid: 0,
      },
      inventories: {
        active: 0,
        avoid: 0,
      },
    });

    const skus = computed(() => status.value.skus);
    const locations = computed(() => status.value.locations);
    const inventories = computed(() => status.value.inventories);

    const { getReplenishmentStatus } = useInventoryApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const { data } = await getReplenishmentStatus();

          status.value = data;

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    return {
      fetching,
      fetched,
      fetch,
      skus,
      locations,
      inventories,
    };
  },
);
