import { RouterView } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { IconClipboardList } from '@tabler/icons-vue';
import { authGuard, notAuthGuard, createFeatureFlagGuard } from '../guards';
import { FeatureFlag, useUnleashClient } from '../../services/unleash';
import { useI18n } from '../../services/i18n';
import { useAccountStore } from '../../stores';
import { lineColors } from '../../modules/charts';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../../layouts/default-layout.vue'),
    meta: {
      guards: [authGuard],
    },
    children: [
      {
        path: '',
        redirect: '/inventory',
      },
      {
        path: '/replenishment',
        component: RouterView,
        redirect: '/replenishment/store',
        children: [
          {
            path: ':locationType',
            component: () => import('../../modules/replenishment/views/replenishment-index.vue'),
            props: (route) => ({ locationType: route.params.locationType }),
            redirect: (route) => `/replenishment/${route.params.locationType}/overview`,
            meta: {
              guards: [
                (from, to) => {
                  if (to.params.locationType === 'warehouse') {
                    const store = useAccountStore();
                    return store.whReplenishmentActive || '/replenishment';
                  }

                  if (to.params.locationType !== 'store') {
                    return '/replenishment';
                  }

                  return true;
                },
              ],
            },
            children: [
              {
                path: 'overview',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-overview.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
              },
              {
                path: 'locations',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-locations.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
              },
              {
                path: 'products',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-products.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
              },
              {
                path: 'inventories',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-inventories.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
                meta: {
                  guards: [createFeatureFlagGuard(FeatureFlag.ReplenishmentInventories)],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/analytics',
        component: () => import('../../views/analytics/analytics-index.vue'),
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.Analytics)],
        },
        children: [
          {
            path: 'dashboards/:id',
            component: () => import('../../views/analytics/analytics-dashboard.vue'),
          },
        ],
      },
      {
        path: '/planning-assistant',
        component: () => import('../../views/analytics/analytics-index.vue'),
        meta: {
          guards: [
            createFeatureFlagGuard(FeatureFlag.Analytics),
            createFeatureFlagGuard(FeatureFlag.AnalyticsPlanningAssistant),
          ],
        },
        children: [
          {
            path: 'dashboards/:id',
            component: () => import('../../views/analytics/analytics-dashboard.vue'),
            props: {
              icon: IconClipboardList,
              iconBackground: lineColors.celticBlue,
            },
          },
        ],
      },
      {
        path: '/analytics-embedded',
        component: () => import('../../views/analytics/analytics-index.vue'),
        children: [
          {
            path: 'dashboards/:id',
            component: () => import('../../views/analytics/analytics-dashboard-embedded.vue'),
          },
        ],
      },
      {
        path: '/inventory',
        component: RouterView,
        children: [
          {
            path: '',
            component: () => import('../../modules/inventory/views/inventory-index.vue'),
            redirect: '/inventory/location',
            children: [
              {
                path: 'location',
                component: () => import('../../modules/inventory/views/inventory-location.vue'),
              },
              {
                path: 'sku',
                component: () => import('../../modules/inventory/views/inventory-sku.vue'),
              },
              {
                path: 'inventories',
                component: () =>
                  useUnleashClient()?.isEnabled(FeatureFlag.InventoryNewReplenishmentStatus)
                    ? import('../../modules/inventory/views/inventory-inventories.vue')
                    : import('../../modules/inventory/views/inventory-inventories-old.vue'),
              },
              {
                path: 'transactions',
                component: () => import('../../modules/inventory/views/inventory-transactions.vue'),
                meta: {
                  guards: [createFeatureFlagGuard(FeatureFlag.InventoryTransactions)],
                },
              },
            ],
          },
          {
            path: 'inventories/:id',
            component: () => import('../../modules/sku-chart/views/sku-chart.vue'),
            props: {
              breadcrumbs: ['/inventory', '/inventory/inventories'],
            },
          },
          {
            path: 'location/avoid-replenishment-upload',
            component: () =>
              import('../../modules/inventory/views/inventory-avoid-replenishment-upload.vue'),
            props: () => {
              const { t } = useI18n();

              return {
                breadcrumbs: [
                  {
                    label: t('modules.inventory.messages.breadcrumbInventory'),
                    href: '/inventory',
                  },
                  {
                    label: t('modules.inventory.messages.breadcrumbLocations'),
                    href: '/inventory/location',
                  },
                  {
                    label: t(
                      'modules.inventory.messages.avoidReplenishmentSettingsUploadingPageTitle',
                    ),
                    href: '/inventory/location/avoid-replenishment-upload',
                  },
                ],
              };
            },
            meta: {
              guards: [createFeatureFlagGuard(FeatureFlag.InventoryAvoidReplenishmentUpload)],
            },
          },
          {
            path: 'sku/avoid-replenishment-upload',
            component: () =>
              import('../../modules/inventory/views/inventory-avoid-replenishment-upload.vue'),
            props: () => {
              const { t } = useI18n();

              return {
                breadcrumbs: [
                  {
                    label: t('modules.inventory.messages.breadcrumbInventory'),
                    href: '/inventory',
                  },
                  {
                    label: t('modules.inventory.messages.breadcrumbSkus'),
                    href: '/inventory/sku',
                  },
                  {
                    label: t(
                      'modules.inventory.messages.avoidReplenishmentSettingsUploadingPageTitle',
                    ),
                    href: '/inventory/sku/avoid-replenishment-upload',
                  },
                ],
              };
            },
            meta: {
              guards: [createFeatureFlagGuard(FeatureFlag.InventoryAvoidReplenishmentUpload)],
            },
          },
          {
            path: 'inventories/avoid-replenishment-upload',
            component: () =>
              import('../../modules/inventory/views/inventory-avoid-replenishment-upload.vue'),
            props: () => {
              const { t } = useI18n();

              return {
                breadcrumbs: [
                  {
                    label: t('modules.inventory.messages.breadcrumbInventory'),
                    href: '/inventory',
                  },
                  {
                    label: t('modules.inventory.messages.breadcrumbInventories'),
                    href: '/inventory/inventories',
                  },
                  {
                    label: t(
                      'modules.inventory.messages.avoidReplenishmentSettingsUploadingPageTitle',
                    ),
                    href: '/inventory/inventories/avoid-replenishment-upload',
                  },
                ],
              };
            },
            meta: {
              guards: [createFeatureFlagGuard(FeatureFlag.InventoryAvoidReplenishmentUpload)],
            },
          },
        ],
      },
      {
        path: '/store-transfer',
        component: () => import('../../modules/store-transfer/views/store-transfer-index.vue'),
        redirect: '/store-transfer/recommendations',
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.StoreTransfer)],
        },
        children: [
          {
            path: 'recommendations',
            component: () =>
              import('../../modules/store-transfer/views/store-transfer-recommendations.vue'),
            redirect: '/store-transfer/recommendations/trips',
            children: [
              {
                path: 'trips',
                component: () =>
                  import(
                    '../../modules/store-transfer/views/store-transfer-recommendations-trips.vue'
                  ),
              },
              {
                path: 'products',
                component: () =>
                  import(
                    '../../modules/store-transfer/views/store-transfer-recommendations-products.vue'
                  ),
              },
            ],
          },
          {
            path: 'transfers',
            component: () =>
              import('../../modules/store-transfer/views/store-transfer-transfers.vue'),
          },
        ],
      },
      {
        path: '/allocations',
        component: RouterView,
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.Allocations)],
        },
        children: [
          {
            path: '',
            component: () => import('../../modules/allocations/views/allocations-index.vue'),
            redirect: '/allocations/pending',
            children: [
              {
                path: 'scheduled',
                component: () =>
                  import('../../modules/allocations/views/allocations-scheduled.vue'),
              },
              {
                path: 'pending',
                component: () => import('../../modules/allocations/views/allocations-pending.vue'),
              },
              {
                path: 'approved',
                component: () => import('../../modules/allocations/views/allocations-approved.vue'),
              },
            ],
          },
          {
            path: 'upload',
            component: () => import('../../modules/allocations/views/allocations-upload.vue'),
          },
        ],
      },
      {
        path: '/special-events',
        component: RouterView,
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.SpecialEventsNew)],
        },
        children: [
          {
            path: '',
            redirect: '/special-events/pending',
            component: () => import('../../modules/special-events/views/special-events-index.vue'),
            children: [
              {
                path: 'pending',
                component: () =>
                  import('../../modules/special-events/views/special-events-pending.vue'),
              },
              {
                path: 'upcoming',
                component: () =>
                  import('../../modules/special-events/views/special-events-upcoming.vue'),
              },
              {
                path: 'approved',
                component: () =>
                  import('../../modules/special-events/views/special-events-approved.vue'),
              },
            ],
          },
          {
            path: ':id',
            component: () => import('../../modules/special-events/views/special-event.vue'),
          },
          {
            path: 'upload',
            component: () => import('../../modules/special-events/views/special-events-upload.vue'),
          },
        ],
      },
      {
        path: '/sku-chart/:id',
        component: () => import('../../modules/sku-chart/views/sku-chart.vue'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      guards: [notAuthGuard],
    },
    component: () => import('../../views/auth-page.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../../views/not-found.vue'),
  },
];
