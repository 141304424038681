import { createSharedComposable } from '@vueuse/shared';
import { useApi } from '../../../../services/api';
import type {
  InventoryKpis,
  LocationKpis,
  SkuKpis,
  Inventory,
  Location,
  Sku,
  LocationDetails,
  InventoryDetails,
  InventoryReplenishmentSettingUpdateTask,
  AvoidReplenishmentStatus,
  InventoryReplenishmentScope,
} from '../../interfaces';
import type {
  MissingItem,
  PaginatedResponse,
  PaginationQuery,
  TransportRequestOptions,
} from '../../../../services/api';

export const useInventoryApi = createSharedComposable(() => {
  const { transport } = useApi();

  // Inventories

  function getInventoryKpis() {
    return transport.get<InventoryKpis>('/v1/inventories/kpis');
  }

  function getInventories(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Inventory>>('/v1/inventories', {
      query,
    });
  }

  function getInventoriesByIds(ids: string[]) {
    return transport.post<{ data: Array<Inventory | MissingItem> }>('/v1/inventories/by_ids', {
      ids,
    });
  }

  function getInventory(id: string) {
    return transport.get<InventoryDetails>(`/v1/inventories/${id}`);
  }

  // Locations

  function getLocationKpis() {
    return transport.get<LocationKpis>('/v1/locations/kpis');
  }

  function getLocations(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Location>>('/v1/locations', {
      query,
    });
  }

  function getLocationsByIds(ids: string[]) {
    return transport.post<{ data: Array<Location | MissingItem> }>('/v1/locations/by_ids', {
      ids,
    });
  }

  function getLocation(id: string) {
    return transport.get<LocationDetails>(`/v1/locations/${id}`);
  }

  // SKUs

  function getSkuKpis() {
    return transport.get<SkuKpis>('/v1/skus/kpis');
  }

  function getSkus(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Sku>>('/v1/skus', {
      query,
    });
  }

  function getSkuByIds(ids: string[]) {
    return transport.post<{ data: Array<Sku | MissingItem> }>('v1/skus/by_ids', {
      ids,
    });
  }

  // Replenishment

  function uploadReplenishmentStatus(
    file: File,
    scope: InventoryReplenishmentScope,
    onUploadProgress?: TransportRequestOptions['onUploadProgress'],
  ) {
    return transport.post<{
      task_id: string;
    }>(
      '/v1/settings/avoid_replenishments/upload',
      { file, scope },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      },
    );
  }

  function getReplenishmentStatus() {
    // This endpoint supports `scope` query param, but it's useless and just brings issues with types
    return transport.get<AvoidReplenishmentStatus>('/v1/settings/avoid_replenishments/status');
  }

  function setReplenishmentStatusByQuery(payload: {
    query: string;
    scope: InventoryReplenishmentScope;
    status: boolean;
  }) {
    return transport.post<{
      task_id: string;
    }>('v1/settings/avoid_replenishments/query', {
      query: payload.query,
      action: payload.status,
      scope: payload.scope,
    });
  }

  async function getLastAvoidReplenishmentSettingsUploadTask(
    scope: 'skus' | 'locations' | 'inventories',
  ) {
    const response = await transport.get<
      PaginatedResponse<InventoryReplenishmentSettingUpdateTask>
    >('/v1/tasks/', {
      query: {
        scope: 'all',
        type: 'AvoidReplenishmentSettingsTask',
        by_user: false,
        page: 1,
        per_page: 1,
        metadata_filter: `{"scope": {"_eq": "${scope}"} }`,
      },
    });

    return response.data.data[0] ?? null;
  }

  function cancelAvoidReplenishmentSettingsUploadTask(id: string) {
    return transport.put(`v1/tasks/abort/${id}`, {});
  }

  return {
    getInventory,
    getInventoryKpis,
    getInventories,
    getInventoriesByIds,
    getLocationKpis,
    getLocations,
    getLocationsByIds,
    getSkuKpis,
    getSkus,
    getSkuByIds,
    getLocation,
    uploadReplenishmentStatus,
    getReplenishmentStatus,
    getLastAvoidReplenishmentSettingsUploadTask,
    cancelAvoidReplenishmentSettingsUploadTask,
    setReplenishmentStatusByQuery,
  };
});
